
export default {
  props: {
    value: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    panelActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      keyword: '',
      disabled: true,
    }
  },

  watch: {
    keyword(val) {
      this.$emit('input', val)
      this.disabled = val == '' || val == ' ' ? true : false
    },
  },

  methods: {
    submit() {
      if (!this.disabled) {
        this.$emit('submit', this.keyword)
      }
    },
  },
}
