export default {
  reviews: [
    {
      rate: 5,
      user_name: '宋小姐',
      location: 'Taiwan',
      title: 'Amazing Quality',
      content: '品質一流，每一樣零食都精選，真的很滿意！',
      date: '2023-03-01',
    },
    {
      rate: 4,
      user_name: '楊先生',
      location: 'Taiwan',
      title: 'Great Choices',
      content: '選擇多元，但有一包餅乾運送時碎了，希望能改善。',
      date: '2023-03-05',
    },
    {
      rate: 5,
      user_name: '蕭小姐',
      location: 'Taiwan',
      title: 'Delightful Experience',
      content: '每次開箱都是一種驚喜，非常推薦給喜愛日式零食的朋友。',
      date: '2023-03-12',
    },
    {
      rate: 4,
      user_name: '張先生',
      location: 'Taiwan',
      title: 'Nice but could be better',
      content: '大體上很滿意，但是一件商品與描述不符，希望能有所改善。',
      date: '2023-03-20',
    },
    {
      rate: 5,
      user_name: '賴小姐',
      location: 'Taiwan',
      title: 'Excellent Service',
      content: '客服態度非常好，解決了我所有問題。零食也很棒！',
      date: '2023-03-28',
    },
    {
      rate: 5,
      user_name: '周小姐',
      location: 'Taiwan',
      title: 'Top Notch Snacks',
      content: '零食非常新鮮，品質超乎預期，下次還會再購買。',
      date: '2023-04-03',
    },
    {
      rate: 4,
      user_name: '黃先生',
      location: 'Taiwan',
      title: 'Very Good but...',
      content: '商品很好，只是運送時間比預期的長，希望能加快。',
      date: '2023-04-10',
    },
    {
      rate: 5,
      user_name: '吳小姐',
      location: 'Taiwan',
      title: 'Fantastic Variety',
      content: '種類豐富，每次都能嘗到新口味，非常滿意！',
      date: '2023-04-17',
    },
    {
      rate: 5,
      user_name: '陳先生',
      location: 'Taiwan',
      title: 'Always Satisfied',
      content: '每次購買都非常滿意，質量和服務都是一流的。',
      date: '2023-04-24',
    },
    {
      rate: 4,
      user_name: '鄭小姐',
      location: 'Taiwan',
      title: 'Good, but with a caveat',
      content: '零食很好吃，但是一個包裝破損，希望下次能包裝得更牢固。',
      date: '2023-05-01',
    },
    {
      rate: 5,
      user_name: '劉先生',
      location: 'Taiwan',
      title: 'Highly Recommended',
      content: '非常推薦這家店，零食新鮮，種類多，服務好。',
      date: '2023-05-08',
    },
    {
      rate: 4,
      user_name: '蔡小姐',
      location: 'Taiwan',
      title: 'Almost Perfect',
      content: '幾乎完美，如果不是一件商品缺貨替換，會更好。',
      date: '2023-05-15',
    },
    {
      rate: 5,
      user_name: '李先生',
      location: 'Taiwan',
      title: 'Exceptional Every Time',
      content: '每次購買都令人印象深刻，品質一致性很高。',
      date: '2023-05-22',
    },
    {
      rate: 4,
      user_name: '林小姐',
      location: 'Taiwan',
      title: 'Very Satisfied, but...',
      content: '非常滿意購買的零食，只希望物流可以更快一些。',
      date: '2023-05-29',
    },
    {
      rate: 5,
      user_name: '張先生',
      location: 'Taiwan',
      title: 'Absolutely Fantastic',
      content: '零食品質絕佳，客服回應快速，非常推薦。',
      date: '2023-06-05',
    },
    {
      rate: 5,
      user_name: '趙小姐',
      location: 'Taiwan',
      title: 'Best Snack Experience',
      content: '是我吃過最好的日式零食，每一樣都很滿意。',
      date: '2023-06-12',
    },
    {
      rate: 4,
      user_name: '周先生',
      location: 'Taiwan',
      title: 'Great, but one issue',
      content: '整體很棒，但是一個商品的味道不是我預期的那樣。',
      date: '2023-06-19',
    },
    {
      rate: 5,
      user_name: '葉小姐',
      location: 'Taiwan',
      title: 'Perfection in a Box',
      content: '每個零食都完美無缺，質量超出預期，非常喜歡。',
      date: '2023-06-26',
    },
    {
      rate: 4,
      user_name: '蔡先生',
      location: 'Taiwan',
      title: 'Really Good, but...',
      content: '商品很好，但是包裝有點簡陋，希望能改善。',
      date: '2023-07-03',
    },
    {
      rate: 5,
      user_name: '張小姐',
      location: 'Taiwan',
      title: 'Top Quality Snacks',
      content: '每次開箱都是一種享受，零食品質一流。',
      date: '2023-07-10',
    },
    {
      rate: 5,
      user_name: '李先生',
      location: 'Taiwan',
      title: 'Will Buy Again',
      content: '一定會再購買，每樣商品都讓人滿意。',
      date: '2023-07-17',
    },
    {
      rate: 4,
      user_name: '黃小姐',
      location: 'Taiwan',
      title: 'Good Overall',
      content: '整體表現不錯，但期待更多新產品的加入。',
      date: '2023-07-24',
    },
    {
      rate: 5,
      user_name: '吳先生',
      location: 'Taiwan',
      title: 'Superb Selection',
      content: '選擇多樣，質量上乘，非常推薦給大家。',
      date: '2023-07-31',
    },
    {
      rate: 5,
      user_name: '陳小姐',
      location: 'Taiwan',
      title: 'Exceptional Quality',
      content: '從包裝到零食的質量都無可挑剔，非常滿意。',
      date: '2023-08-07',
    },
    {
      rate: 4,
      user_name: '鄭先生',
      location: 'Taiwan',
      title: 'Great, but needs more variety',
      content: '品質很好，但希望能有更多種類的選擇。',
      date: '2023-08-14',
    },
    {
      rate: 5,
      user_name: '賴小姐',
      location: 'Taiwan',
      title: 'Absolutely Love It',
      content: '每一樣都超級喜歡，質感、口味都很棒。',
      date: '2023-08-21',
    },
    {
      rate: 5,
      user_name: '周先生',
      location: 'Taiwan',
      title: 'Incredible Flavors',
      content: '風味獨特，每次嘗試都有新驚喜，非常推薦。',
      date: '2023-08-28',
    },
    {
      rate: 4,
      user_name: '吳小姐',
      location: 'Taiwan',
      title: 'Satisfied, but room for improvement',
      content: '總體滿意，但有一項商品的口感與預期不符。',
      date: '2023-09-04',
    },
    {
      rate: 5,
      user_name: '葉先生',
      location: 'Taiwan',
      title: 'Outstanding Quality and Service',
      content: '服務和商品質量都很出色，非常值得推薦。',
      date: '2023-09-11',
    },
    {
      rate: 5,
      user_name: '劉小姐',
      location: 'Taiwan',
      title: 'Always a Pleasure',
      content: '每次購買都非常滿意，服務態度好，商品質優。',
      date: '2023-09-18',
    },
    {
      rate: 4,
      user_name: '蔡先生',
      location: 'Taiwan',
      title: 'Very Good Experience',
      content: '整體經驗很好，只是希望運送速度能更快一些。',
      date: '2023-09-25',
    },
    {
      rate: 5,
      user_name: '張小姐',
      location: 'Taiwan',
      title: 'Exceeded Expectations',
      content: '超出了我的期待，每件商品都讓人驚喜。',
      date: '2023-10-02',
    },
    {
      rate: 5,
      user_name: '李先生',
      location: 'Taiwan',
      title: 'Fantastic Every Time',
      content: '每次購買的體驗都很棒，質量和服務都沒有讓人失望。',
      date: '2023-10-09',
    },
    {
      rate: 4,
      user_name: '黃小姐',
      location: 'Taiwan',
      title: 'Great Taste, Needs More',
      content: '味道很好，但希望能增加更多種類的零食。',
      date: '2023-10-16',
    },
    {
      rate: 5,
      user_name: '吳先生',
      location: 'Taiwan',
      title: 'Perfect for Snack Lovers',
      content: '對於愛吃零食的人來說，這裡是天堂，非常推薦！',
      date: '2023-10-23',
    },
    {
      rate: 5,
      user_name: '陳小姐',
      location: 'Taiwan',
      title: 'Consistently Excellent',
      content: '每次購買的品質都非常穩定，服務也很好。',
      date: '2023-10-30',
    },
    {
      rate: 4,
      user_name: '鄭先生',
      location: 'Taiwan',
      title: 'Good, but More Variety Needed',
      content: '商品質量很好，但希望能有更多的新品種類。',
      date: '2023-11-06',
    },
    {
      rate: 5,
      user_name: '賴小姐',
      location: 'Taiwan',
      title: 'Absolutely Satisfied',
      content: '完全滿意，從質量到服務都是五星級的。',
      date: '2023-11-13',
    },
    {
      rate: 5,
      user_name: '周先生',
      location: 'Taiwan',
      title: 'Best Quality and Service',
      content: '品質和服務都是最好的，每次購買都很放心。',
      date: '2023-11-20',
    },
    {
      rate: 4,
      user_name: '吳小姐',
      location: 'Taiwan',
      title: 'Great Quality, Fast Delivery',
      content: '商品質量很高，快遞也很迅速，只是希望包裝可以再穩固一些。',
      date: '2023-11-27',
    },
    {
      rate: 5,
      user_name: '葉先生',
      location: 'Taiwan',
      title: 'Never Disappoints',
      content: '每次購買都非常滿意，從未讓我失望過。',
      date: '2023-12-04',
    },
    {
      rate: 5,
      user_name: '劉小姐',
      location: 'Taiwan',
      title: 'Always a Hit',
      content: '每次購買都很受歡迎，無論是自用還是送禮都非常合適。',
      date: '2023-12-11',
    },
    {
      rate: 4,
      user_name: '蔡先生',
      location: 'Taiwan',
      title: 'Impressed but Want More',
      content: '印象很好，質量上乘，只是期待未來能有更多新口味。',
      date: '2023-12-18',
    },
    {
      rate: 5,
      user_name: '張小姐',
      location: 'Taiwan',
      title: 'Top Choice for Snacks',
      content: '是選擇零食的首選，無論是口味還是品質都很滿意。',
      date: '2023-12-25',
    },

    {
      rate: 4,
      user_name: '黃小姐',
      location: 'Taiwan',
      title: 'Satisfied but Hoping for More',
      content: '總體滿意，只是希望能有更多創新的零食加入。',
      date: '2024-01-08',
    },
    {
      rate: 4,
      user_name: '趙小姐',
      location: 'Taiwan',
      title: 'Very Pleased',
      content: '非常滿意，只希望選擇能更多一些。',
      date: '2024-01-25',
    },
    {
      rate: 4,
      user_name: '陳小姐',
      location: 'Taiwan',
      title: 'Great Box!',
      content: `此次的零食箱也很有趣，用冰牛奶沖泡的飲品很少見，<br/>
      另外我收到的零食箱跟fb公布的比起來少了一件，綠色包裝的crunky...<br/>
      但章魚燒有4顆XD`,
      date: '2022-07-12',
    },
    {
      rate: 5,
      user_name: '陳小姐',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '零食箱內容都很喜歡，謝謝挑選的用心~',
      date: '2022-06-17',
    },
    {
      rate: 5,
      user_name: '陳小姐',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '很喜歡這次的kanro軟糖還有ghana巧克力，橫濱家系泡麵也很棒~謝謝挑選的用心!!',
      date: '2022-06-13',
    },
    {
      rate: 4,
      user_name: '大佑',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '這個產品很用心雖然說沒有每個都是我想要吃的，但是這個伴手禮盒很多東西點心都很用心。',
      date: '2022-05-24',
    },
    {
      rate: 5,
      user_name: '曾國偉',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '想不到還有這種服務，去不了日本，算一算這些零食也真夠划算的，大推！',
      date: '2022-05-23',
    },
    {
      rate: 5,
      user_name: '阿賢',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '好大一盒真滿足開始期待下個月會出什麼新鮮貨哈',
      date: '2022-05-20',
    },
    {
      rate: 4,
      user_name: 'JC',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '禮盒有些東西還不錯，但是抹茶商品放多一點我會更愛',
      date: '2022-05-11',
    },
    {
      rate: 3,
      user_name: '妙小姐',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '下次可以有Pocky嗎？',
      date: '2022-05-10',
    },
    {
      rate: 5,
      user_name: 'Vicky',
      location: 'Taiwan',
      title: 'Great Box!',
      content:
        '我上次訂了他們的神戶的草莓巧克力，想吃其他的不知道怎麼選，就訂了他們的伴手禮盒吃到現在兩個月了，每天下班都期待回家開一包來試試',
      date: '2021-05-03',
    },
    {
      rate: 3,
      user_name: '凱鈞',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '可以出一盒20種或30種以上的嗎？',
      date: '2022-04-25',
    },
    {
      rate: 5,
      user_name: '大維',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '每天都是零食天吃著來自日本的零食想到一起去日本玩的時光',
      date: '2022-04-24',
    },
    {
      rate: 5,
      user_name: '大維',
      location: 'Taiwan',
      title: 'Great Box!',
      content:
        '我一開始抱著嘗試的心情試試他們家的點心，每想到從結帳到收到貨他們都很貼心回應我，收到的當天跟家人分享，他們都很喜歡，這間我下次一樣會再跟他們訂',
      date: '2022-04-24',
    },
    {
      rate: 3,
      user_name: '妞妞',
      location: 'Taiwan',
      title: 'Great Box!',
      content:
        '上次帶小孩去東京玩，吃了日本的東西現在每天都在吵，剛好看到這個，訂下去家裡那幾隻總算安靜多了哈',
      date: '2022-04-12',
    },
    {
      rate: 5,
      user_name: '曉梅',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '這個疫情真是又臭又長悶死人了，吃了這些零食正好可以洩憤，真是舒壓❤️',
      date: '2022-03-27',
    },
    {
      rate: 5,
      user_name: '小管',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '這禮盒收到感覺他們有用心耶，裡面的每一樣零食都不隨便！真的太好了哈哈哈',
      date: '2022-03-19',
    },
    {
      rate: 5,
      user_name: 'Emma',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '收到禮盒有感動耶，每吃一口點心盒的零食就好想日本＾o＾',
      date: '2022-03-14',
    },
    {
      rate: 5,
      user_name: 'MAX',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '方便、快速、好吃👍',
      date: '2022-03-12',
    },
    {
      rate: 5,
      user_name: '小妍',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '真是誠意十足，家人都吃得很開心，會考慮過年過節用這個來送禮一下❤️',
      date: '2022-03-08',
    },
    {
      rate: 5,
      user_name: '牛奶',
      location: 'Taiwan',
      title: 'Great Box!',
      content: '建議可以搭配一下日本季節或活動，更新一下點心盒內容，除此之外都很棒！',
      date: '2022-03-04',
    },
    {
      rate: 5,
      user_name: '文全',
      location: 'Taiwan',
      title: 'Great Box!',
      content: `比想像中很多零食，還不錯`,
      date: '2022-02-14',
    },
    {
      rate: 4,
      user_name: '莎莎',
      location: 'Taiwan',
      title: 'Great Box!',
      content: `想吃多一點鹹的餅乾，不過其他的餅乾也不錯吃！`,
      date: '2022-02-12',
    },
    {
      rate: 4,
      user_name: '玉姍',
      location: 'Taiwan',
      title: 'Great Box!',
      content: `太久沒去日本，盒子裡的零食都很有特色的日本零食，但是期待可以多放一些泡麵。`,
      date: '2022-02-10',
    },
    {
      rate: 5,
      user_name: 'Han',
      location: 'Taiwan',
      title: 'Great Box!',
      content: `很喜歡盒子裡的所有零食！<br/>
        每個零食都很好吃，<br/>
        而且每個月都能收到各種不同口味和類型的日本零食，<br/>
        能吃到當地特色零食`,
      date: '2021-01-14',
    },
    {
      rate: 5,
      user_name: 'Ivan',
      location: 'Taiwan',
      title: 'Great Box!',
      content:
        '因為疫情已經很久不能去日本，本來就很喜歡到處買日本伴手禮，開始期待下個月會收到什麼零食。',
      date: '2021-01-15',
    },
    {
      rate: 5,
      user_name: '陳小姐',
      location: 'Taiwan',
      title: 'Highly Recommended',
      content: '每月的零食盒是我的期待，超級好吃！',
      date: '2024-03-12',
    },
    {
      rate: 4,
      user_name: '張先生',
      location: 'Taiwan',
      title: 'Good but Room for Improvement',
      content: '有些零食不是我的口味，但總體來說還是不錯的選擇。',
      date: '2024-03-15',
    },
    {
      rate: 5,
      user_name: '李小姐',
      location: 'Taiwan',
      title: 'Excellent Snacks',
      content: '這些零食是我每天期待的一部分，超級推薦！',
      date: '2024-03-18',
    },
    {
      rate: 5,
      user_name: '黃先生',
      location: 'Taiwan',
      title: 'Perfect for Snack Lovers',
      content: '我非常喜歡這個零食盒，每月都會有驚喜。',
      date: '2024-03-22',
    },
    {
      rate: 4,
      user_name: '林小姐',
      location: 'Taiwan',
      title: 'Good Variety',
      content: '零食種類多，但希望能有更多健康的選擇。',
      date: '2024-03-25',
    },
    {
      rate: 5,
      user_name: '周先生',
      location: 'Taiwan',
      title: 'Fantastic Experience',
      content: '零食盒裡的每一樣東西都很棒，值得購買。',
      date: '2024-03-30',
    },
    {
      rate: 4,
      user_name: '鄭小姐',
      location: 'Taiwan',
      title: 'Satisfactory',
      content: '整體不錯，但有些產品不是我的喜好。',
      date: '2024-04-02',
    },
    {
      rate: 5,
      user_name: '吳先生',
      location: 'Taiwan',
      title: 'Best Monthly Box',
      content: '這是我訂購過最好的月訂購服務。',
      date: '2024-04-06',
    },
    {
      rate: 5,
      user_name: '彭小姐',
      location: 'Taiwan',
      title: 'Delicious Treats',
      content: '每次打開這個盒子都充滿了驚喜，超愛！',
      date: '2024-04-10',
    },
    {
      rate: 4,
      user_name: '高先生',
      location: 'Taiwan',
      title: 'Good Snacks',
      content: '零食不錯，但包裝有時候會破損。',
      date: '2024-04-13',
    },
    {
      rate: 5,
      user_name: '許小姐',
      location: 'Taiwan',
      title: 'Absolutely Love It',
      content: '這些零食是我的最愛，我會繼續訂購。',
      date: '2024-04-17',
    },
    {
      rate: 5,
      user_name: '梁先生',
      location: 'Taiwan',
      title: 'Worth Every Penny',
      content: '這個零食盒物超所值，每月都帶來驚喜。',
      date: '2024-04-21',
    },
    {
      rate: 4,
      user_name: '羅小姐',
      location: 'Taiwan',
      title: 'Nice Assortment',
      content: '種類豐富，但有些不是我的口味。',
      date: '2024-04-25',
    },
    {
      rate: 5,
      user_name: '何先生',
      location: 'Taiwan',
      title: 'Best Purchase Ever',
      content: '這是我買過最棒的零食訂閱服務。',
      date: '2024-04-28',
    },
    {
      rate: 5,
      user_name: '蔡小姐',
      location: 'Taiwan',
      title: 'Incredible Snacks',
      content: '每一樣零食都讓我驚喜連連，非常推薦！',
      date: '2024-05-02',
    },
    {
      rate: 4,
      user_name: '王先生',
      location: 'Taiwan',
      title: 'Good Service',
      content: '服務很好，但有些零食不太合口味。',
      date: '2024-05-06',
    },
    {
      rate: 5,
      user_name: '劉小姐',
      location: 'Taiwan',
      title: 'Superb Quality',
      content: '品質優良，每次都期待收到零食盒。',
      date: '2024-05-10',
    },
    {
      rate: 5,
      user_name: '鄧先生',
      location: 'Taiwan',
      title: 'Highly Satisfied',
      content: '每次都能品嚐到新奇的零食，超喜歡！',
      date: '2024-05-14',
    },
    {
      rate: 4,
      user_name: '章小姐',
      location: 'Taiwan',
      title: 'Good but Pricey',
      content: '零食不錯，但價格略高。',
      date: '2024-05-18',
    },
    {
      rate: 5,
      user_name: '馬先生',
      location: 'Taiwan',
      title: 'Love the Variety',
      content: '種類豐富，口感很好，值得購買。',
      date: '2024-05-22',
    },
    {
      rate: 5,
      user_name: '范小姐',
      location: 'Taiwan',
      title: 'Best Subscription',
      content: '這是我最愛的訂閱服務，永遠讓我驚喜。',
      date: '2024-05-26',
    },
    {
      rate: 4,
      user_name: '鍾先生',
      location: 'Taiwan',
      title: 'Nice but Can Improve',
      content: '整體不錯，但有時候零食有點單一。',
      date: '2024-05-29',
    },
    {
      rate: 5,
      user_name: '蘇小姐',
      location: 'Taiwan',
      title: 'Great Experience',
      content: '每次都期待著收到零食盒，非常滿意！',
      date: '2024-06-03',
    },
    {
      rate: 5,
      user_name: '簡先生',
      location: 'Taiwan',
      title: 'Perfect Snacks',
      content: '每次收到零食盒都非常滿足，真是太棒了！',
      date: '2024-06-07',
    },
    {
      rate: 4,
      user_name: '戴小姐',
      location: 'Taiwan',
      title: 'Good but Needs More Options',
      content: '整體不錯，但希望能增加更多不同種類的零食。',
      date: '2024-06-11',
    },
    {
      rate: 5,
      user_name: '馮先生',
      location: 'Taiwan',
      title: 'Excellent Service',
      content: '服務非常好，零食也很棒，超喜歡！',
      date: '2024-06-15',
    },
    {
      rate: 5,
      user_name: '程小姐',
      location: 'Taiwan',
      title: 'Super Satisfying',
      content: '每次打開零食盒都是一種享受，超級滿意！',
      date: '2024-06-19',
    },
    {
      rate: 4,
      user_name: '許先生',
      location: 'Taiwan',
      title: 'Good Quality but Could Improve',
      content: '零食的品質很好，但希望能有更多新品種。',
      date: '2024-06-23',
    },
    {
      rate: 5,
      user_name: '唐小姐',
      location: 'Taiwan',
      title: 'Fantastic Selection',
      content: '這些零食都是我喜歡的，非常滿意！',
      date: '2024-06-27',
    },
    {
      rate: 5,
      user_name: '楊先生',
      location: 'Taiwan',
      title: 'Always a Delight',
      content: '每次收到零食盒都讓我非常開心，真的是超棒的服務！',
      date: '2024-07-01',
    },
    {
      rate: 4,
      user_name: '劉小姐',
      location: 'Taiwan',
      title: 'Good but Needs More Variety',
      content: '整體來說不錯，但希望能增加更多口味的零食。',
      date: '2024-07-05',
    },
    {
      rate: 5,
      user_name: '黃先生',
      location: 'Taiwan',
      title: 'Best Snacks Ever',
      content: '這些零食是我吃過最好的，超級推薦！',
      date: '2024-07-09',
    },
    {
      rate: 5,
      user_name: '鄭小姐',
      location: 'Taiwan',
      title: 'Superb Monthly Box',
      content: '這是我每月最期待的時刻，非常滿足！',
      date: '2024-07-13',
    },
    {
      rate: 4,
      user_name: '周先生',
      location: 'Taiwan',
      title: 'Nice but Pricey',
      content: '零食不錯，但覺得有點貴。',
      date: '2024-07-17',
    },
    {
      rate: 5,
      user_name: '張小姐',
      location: 'Taiwan',
      title: 'Highly Recommended',
      content: '這些零食真的超棒，值得一試！',
      date: '2024-07-21',
    },
    {
      rate: 5,
      user_name: '陳先生',
      location: 'Taiwan',
      title: 'Best Purchase Ever',
      content: '這是我最喜歡的購買之一，零食都很美味。',
      date: '2024-07-25',
    },
    {
      rate: 4,
      user_name: '何小姐',
      location: 'Taiwan',
      title: 'Good Quality Snacks',
      content: '零食的品質不錯，但種類有點少。',
      date: '2024-07-29',
    },
    {
      rate: 5,
      user_name: '林先生',
      location: 'Taiwan',
      title: 'Excellent Selection',
      content: '這些零食都很美味，非常滿意這個服務。',
      date: '2024-08-02',
    },
    {
      rate: 5,
      user_name: '王小姐',
      location: 'Taiwan',
      title: 'Great Value for Money',
      content: '這個零食盒非常值得，零食都超級好吃。',
      date: '2024-08-06',
    },
    {
      rate: 4,
      user_name: '許先生',
      location: 'Taiwan',
      title: 'Good but Can Improve',
      content: '整體不錯，但包裝有時候會破損。',
      date: '2024-08-10',
    },
    {
      rate: 5,
      user_name: '謝小姐',
      location: 'Taiwan',
      title: 'Amazing Experience',
      content: '這個零食盒讓我非常滿意，超級推薦！',
      date: '2024-08-14',
    },
  ],
}
