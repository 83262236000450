import _ from 'lodash'
export default {
  computed: {
    auth() {
      return this.$store.state.auth
    },
    authUser() {
      return this.auth.user || {}
    },
    socialAuth() {
      return _.get(this.authUser, 'social.0', {})
    },
    isLogin() {
      return this.auth.loggedIn
    },
    loggedWithSocial() {
      return this.auth.user.logged_in_method != 'GENERAL'
    },
    authUserName() {
      return `${this.authUser.first_name} ${this.authUser.last_name} `
    },
    isEmailVerified() {
      return this.auth.user.email_verified_at != ('' || null)
    },
  },
}
