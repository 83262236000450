
import Logo from '@/components/shared/logo.vue'
import CurrencyMixin from '@/mixins/currency'

export default {
  components: { Logo },
  mixins: [CurrencyMixin],
  props: {
    white: {
      type: Boolean,
      return: false,
    },
  },

  data() {
    return {
      input: {
        locale: this.$i18n.localeProperties.code,
        currency: this.$cookies.get('currency'),
      },
      locales: [],
      currencies: [],
    }
  },
  computed: {
    email() {
      return process.env.MAIL_ADDRESS
    },
  },
  watch: {
    '$i18n.localeProperties.code': {
      handler: function (newValue) {
        this.input.locale = newValue
      },
    },
  },
  created() {
    this.getLocale()
    this.getCurrency()
  },
  methods: {
    /**
     * @description: Fetch locales.
     * @param {*}
     * @return {*}
     */
    getLocale() {
      this.$i18n.locales.forEach((locale) => {
        this.locales.push({ text: locale.name, value: locale.code })
      })
    },

    /**
     * @description: Fetch currencies.
     * @param {*}
     * @return {*}
     */
    getCurrency() {
      this.currencies.push({ text: 'TWD', value: 'TWD' })
    },

    /**
     * @description: Language switch.
     * @param {*}
     * @return {*}
     */
    switchLocale() {
      this.$i18n.setLocale(this.input.locale)
      // set moment locale
      this.$moment.locale(this.input.locale)
    },

    /**
     * @description: Language currency.
     * @param {*}
     * @return {*}
     */
    switchCurrency() {
      this.changeCurrency(this.input.currency)
    },
  },
}
