
import layoutMixins from '@/mixins/events'
import CookieGDPRMixins from '@/mixins/gdpr'
import HeaderNavbar from '@/components/header'
import Dock from '@/components/dock'
import FooterNavbar from '@/components/footer'
import CookieTerms from '@/components/cookie'

export default {
  name: 'JafunApp',

  components: {
    HeaderNavbar,
    Dock,
    FooterNavbar,
    CookieTerms,
  },

  mixins: [layoutMixins, CookieGDPRMixins],

  data() {
    return {
      modal_open: false,
      header: {
        fixed: false,
        transparent: true,
      },
      dock: {
        active: true,
      },
    }
  },
  mounted() {
    const { query } = this.$route
    const { referrer_code } = query
    if (referrer_code) {
      this.$cookies.set('referrer_code', referrer_code)
    }
  },
}
