
import authMixins from '@/mixins/auth'
export default {
  mixins: [authMixins],
  props: {
    products: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    /**
     * Handle logout
     */
    handleLogout() {
      this.$auth.logout().then(() => {
        if (this.$route.path === '/subscribe') {
          return this.$router.push('/subscribe')
        }
        return this.$router.push(this.localePath('/'))
      })
    },
    onOver() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.show()
      }
    },
    onLeave() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.hide()
      }
    },
  },
}
