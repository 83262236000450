
export default {
  props: {
    width: {
      type: Number,
      default: 125,
    },
    color: {
      type: String,
      default: '#ffffff',
    },
  },
}
