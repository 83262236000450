
export default {
  props: {
    active: Boolean,
  },
  data() {
    return {
      input: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        password: '',
        password_confirmation: '',
        nationality: 'Taiwan',
        gender: 'MALE',
        birthday: '',
        verify_method: 'EMAIL',
      },
      submit: false,
      show_verify_code: false,
      error_msg: {
        detail: {},
      },
    }
  },
  methods: {
    async handleRegister() {
      try {
        const referrer_code = this.$cookies.get('referrer_code')
        const data = {
          ...this.input,
          referrer_code,
        }
        await this.$axios.post('/register', data)
        this.$toast.success(this.$t('register.success_message'))
        this.submit = true

        if (referrer_code) {
          this.$cookies.remove('referrer_code')
        }
      } catch (e) {
        this.error_msg = e
        this.$toast.error(this.$t('register.error_message'))
      }
    },
    /**
     * Input eyes icon toggle revealing password
     */
    handleShowPwd(e) {
      const status = e.target.className
      const el = [...e.target.offsetParent.children]
      const _target = el.find((element) => element.localName == 'input')

      let _type, _class

      if (status == 'fa fa-eye') {
        _type = 'password'
        _class = 'fa fa-eye-slash'
      } else if (status == 'fa fa-eye-slash') {
        _type = 'text'
        _class = 'fa fa-eye'
      }
      _target.setAttribute('type', _type)
      e.target.className = _class
    },
    handleContent(mode) {
      this.$emit('handler', mode)
    },
    pickerClosed() {
      if (this.input.birthday) {
        this.input.birthday = this.$moment(this.input.birthday).format('YYYY-MM-DD')
      }
    },
  },
}
