
import toastMixin from '@/mixins/toast'

export default {
  modal: {
    event: ['close'],
  },

  mixins: [toastMixin],

  computed: {
    modalTitle() {
      return '驗證電子信箱'
    },
  },

  methods: {
    /**
     * Reset modal.
     */
    resetModal() {
      this.$emit('close')
    },
    /**
     * Verify Email.
     */
    async verifyUserEmail() {
      try {
        await this.$axios.get('user/verify_email')

        this.successToast(this.$t('verify_email.toast.success'))
      } catch (e) {
        this.faildToast(this.$t('verify_email.toast.error'))
      }
    },
  },
}
