
export default {
  props: {
    links: {
      type: Array,
      default() {
        return []
      },
    },

    active: {
      type: Boolean,
      default: true,
    },

    searchActive: {
      type: Boolean,
      default: false,
    },

    loginActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    search_wrap: {
      get() {
        return this.searchActive
      },
      set(newValue) {
        this.$emit('update:searchActive', newValue)
      },
    },
    login_wrap: {
      get() {
        return this.loginActive
      },
      set(newValue) {
        this.$emit('update:loginActive', newValue)
      },
    },
  },

  watch: {
    links: {
      handler(val) {
        return val
      },
      deep: true,
    },
  },

  methods: {
    handler(e) {
      const ev_name = e.target.dataset.event

      if (ev_name == 'toggle-search') {
        this.search_wrap = !this.search_wrap
      } else if (ev_name == 'toggle-login') {
        this.$root.$emit('bv::show::modal', 'Login')
      }
    },
  },
}
