
import layoutMixins from '@/mixins/events'
import HeaderNavbar from '@/components/header'

export default {
  name: `JafunApp`,
  components: {
    HeaderNavbar,
  },
  mixins: [layoutMixins],
  data() {
    return {
      modal_open: false,
      header: {
        fixed: false,
        transparent: false,
      },
      dock: {
        active: true,
      },
    }
  },
}
