
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      notifications: 'user/notification/list',
    }),

    messages() {
      return this.notifications.length ? this.notifications.slice(0, 6) : []
    },
  },

  async mounted() {
    await this.fetchNotifications({
      page: 1,
    })
  },

  methods: {
    ...mapActions({
      fetchNotifications: 'user/notification/fetchList',
      fetchRead: 'user/notification/fetchRead',
    }),

    handleMessageClick(id, url) {
      this.fetchRead({
        id: id,
        page: this.page,
      })
      this.fetchNotifications({
        page: this.page,
      })
      setTimeout(() => {
        this.fetchNotifications({
          page: this.page,
        })
      }, 100)

      if (url) {
        window.location = url
      }

      this.$router.push(this.localePath('/user/message'))
    },

    onOver() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.show()
      }
    },
    onLeave() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.hide()
      }
    },

    /**
     * @description: Convert datetime to relative time,ex: a hours ago.
     * @param {*} datetime
     * @return {*}
     */
    notifyRelativeTime(datetime) {
      this.$moment.locale(this.$i18n.localeProperties.code)
      return this.$moment(datetime, 'YYYYMMDDHH:mm:ss').fromNow()
    },
  },
}
