// import { Client } from '@notionhq/client'
import ReviewsData from '@/static/reviews'

export const state = () => ({
  subscribe: {
    features_list: [],
    plans: [],
    kv_images: [],
  },
  reviews: {
    summary: {
      average: 4.7,
      reviews_total: 70,
      band_list: [
        { rate: 5, total: 43 },
        { rate: 4, total: 20 },
        { rate: 3, total: 7 },
        { rate: 2, total: 0 },
        { rate: 1, total: 0 },
      ],
    },
    list: ReviewsData.reviews,
  },
})

export const mutations = {
  setSubscription(state) {
    state.subscribe.plans = [
      {
        id: 1,
        title: '12 個月',
        months: '12',
        coupon_text: '節省$948',
        label: {
          text: 'Best Value',
          variant: 'info',
        },
        price: '920',
        per: '月',
        url: 'https://buy.stripe.com/3cseYV6gc5PbfZu3cm',
      },
      {
        id: 2,
        title: '6 個月',
        months: '6',
        coupon_text: '節省$324',
        label: {
          text: 'Popular',
          variant: 'warnning',
        },
        price: '945',
        per: '月',
        url: 'https://buy.stripe.com/28ocQN5c83H33cIaEN',
      },
      {
        id: 3,
        title: '3 個月',
        months: '3',
        coupon_text: '節省$102',
        price: '965',
        per: '月',
        url: 'https://buy.stripe.com/4gwg2Z7kgelH5kQ008',
      },
      {
        id: 4,
        title: '1 個月',
        price: '999',
        months: '1',
        coupon_text: '嘗鮮方案！',
        per: '月',
        url: 'https://buy.stripe.com/cN2aIF340cdz14AaEL',
      },
    ]

    state.subscribe.features_list = [
      {
        icon: require('~/assets/img/sub/soft-drink.png'),
        title: '日本限定飲品',
        summary: '綾鷹カフェ，午後限定系列等',
      },
      {
        icon: require('~/assets/img/sub/snack.png'),
        title: '日本限定零食',
        summary: '鬼滅限定零食，Pocky限定，日本限定洋芋片等',
      },
      {
        icon: require('~/assets/img/sub/hishi-mochi.png'),
        title: '日本當地精選伴手禮',
        summary: '每月精選當地有故事，有特色又好吃的伴手禮',
      },
      {
        icon: require('~/assets/img/sub/ramen.png'),
        title: '日本道地拉麵',
        summary: '當地人氣拉麵店家自家製拉麵，限定泡麵等',
      },
    ]

    state.subscribe.kv_images = [
      {
        url: 'https://jafun.s3.ap-northeast-1.amazonaws.com/banner/box_5.png',
        title: '',
      },
      {
        url: 'https://jafun.s3.ap-northeast-1.amazonaws.com/banner/box_6.png',
        title: '',
      },
      {
        url: 'https://jafun.s3.ap-northeast-1.amazonaws.com/banner/box_7.png',
        title: '',
      },
      {
        url: 'https://jafun.s3.ap-northeast-1.amazonaws.com/banner/box_8.png',
        title: '',
      },
      {
        url: 'https://jafun.s3.ap-northeast-1.amazonaws.com/banner/box_9.png',
        title: '',
      },
    ]
  },
  setReviewsGroupByValue(state, payload) {
    const newList = ReviewsData.reviews.filter((item) => item.rate == payload)
    state.reviews.list = newList
  },
}

export const actions = {
  async getSubscription({ commit }) {
    commit('setSubscription')
  },
  async getReviewsGroupByValue({ commit }, payload) {
    commit('setReviewsGroupByValue', payload)
  },
  // async getRate({ commit }) {
  //   const notion = new Client({ auth: 'secret_zccpzQTyfPAvkghoh3rgZJu0BHeGiGxWP3FxmK8FvJ4' })
  //   const ratings = await notion.databases.query({
  //     database_id: '1e0ba0763dc04e16a62c421bca189f30',
  //   })

  //   const results = ratings.results
  //     .map((v) => {
  //       return {
  //         id: v.id ?? null,
  //         date: v.properties.date?.date.start ?? null,
  //         user_name: v.properties.name?.title[0]?.plain_text ?? null,
  //         location: v.properties.area?.select.name ?? null,
  //         rate: v.properties.rating?.number ?? null,
  //         title: v.properties.title?.rich_text[0].plain_text ?? null,
  //         content: v.properties.comment?.rich_text[0].plain_text ?? null,
  //       }
  //     })
  //     .sort((a, b) => {
  //       const first = a.date
  //       const second = b.date
  //       return first < second ? 1 : first > second ? -1 : 0
  //     })

  //   commit('setRatings', results)
  // },
}
