
import { mapState } from 'vuex'
import layoutMixins from '@/mixins/events'
import CookieGDPRMixins from '@/mixins/gdpr'
import HeaderNavbar from '@/components/header'
import FooterNavbar from '@/components/footer'
import CookieTerms from '@/components/cookie'

export default {
  name: 'JafunApp',

  components: {
    HeaderNavbar,
    FooterNavbar,
    CookieTerms,
  },

  mixins: [layoutMixins, CookieGDPRMixins],

  data() {
    return {
      modal_open: false,
      header: {
        fixed: false,
        transparent: false,
      },
      search: {
        active: false,
      },
      dock: {
        active: true,
      },
    }
  },

  computed: {
    ...mapState('product/detail', [
      'product',
      'error_message',
      'comments',
      'comment_error_message',
    ]),
  },
}
