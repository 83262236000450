
export default {
  methods: {
    onOver() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.show()
      }
    },
    onLeave() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.hide()
      }
    },
  },
}
